import React from 'react';
import {
  Routes,
  Route,
  useNavigate,
} from 'react-router-dom';
import WebsiteOne from './WebsiteOne';
import WebsiteTwo from './WebsiteTwo';
import WebsiteThree from './WebsiteThree';
import './App.css';

function App() {
  const navigate = useNavigate();
  const handleURLChange = (newValue) =>{ 
    navigate(newValue);
  }
  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '15px',
      }}>
        <button
          onClick={() => handleURLChange('WebsiteOne')}
          style={{
            cursor: 'pointer',
            marginRight: '10px',
          }}
        >
          Website One
        </button>
        <button
          onClick={() => handleURLChange('WebsiteTwo')}
          style={{
            cursor: 'pointer',
          }}
        >
          Website Two
        </button>
        <button
          onClick={() => handleURLChange('WebsiteThree')}
          style={{
            cursor: 'pointer',
          }}
        >
          Website Three
        </button>
      </div>
      <Routes>
        <Route
          path="/WebsiteOne/*"
          element={(
            <WebsiteOne />
          )}
        />
        <Route
          path="/WebsiteTwo/*"
          element={(
            <WebsiteTwo />
          )}
        />
        <Route
          path="/WebsiteThree/*"
          element={(
            <WebsiteThree />
          )}
        />
      </Routes>
    </div>
  );
}

export default App;
