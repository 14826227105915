import React, {useEffect} from 'react';

const TESTING_LOCALHOST = false;

export default function WebsiteTwo() {
  useEffect(() => {
    if (document.getElementById('hio-script-identifier')) {
      document.getElementById('hio-script-identifier').remove();
    }

    if (document.getElementById('hovr-site-identifier')) {
      document.getElementById('hovr-site-identifier').remove();
    }
    let hovr;
    hovr = document.createElement('script');
    hovr.id = 'hio-script-identifier';
    hovr.setAttribute("data-id", '3Xpm4IPSA2BzKTfwvpoX');
    hovr.defer = true;
    hovr.src = TESTING_LOCALHOST? 'http://localhost:8080/bundle.js' : 'https://scriptdev.gethovr.com/bundle.js';
    document.head.appendChild(hovr);
  }, []);
  return (
    <div
      style={{
        height: '100%',
        width: '100%'
      }}
    >
      <h1>
        HOTEL THREE
      </h1>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '8px',
        }}
      >
        <div
          style={{
            height: '200px',
            width: '200px',
            backgroundColor: 'green',
          }}
        >
          block 1
        </div>
        <div
          style={{
            height: '200px',
            width: '200px',
            backgroundColor: 'red',
          }}
        >
          block 2
        </div>
        <div
          style={{
            height: '200px',
            width: '200px',
            backgroundColor: 'blue',
          }}
        >
          block 3 with test to test the highlights
        </div>
      </div>
    </div>
  )
}